<header
  [ngClass]="{
    basic: isBasic,
    secured: isSecured,
    'logged-in': (loggedIn$ | async),
    'not-logged-in': (loggedIn$ | async) === false,
    'trading-platform': isTradingPlatform
  }"
>
  <div class="main">
    <div class="content">
      <!-- show basic menu w/ back btn -->
      <ng-container *ngIf="isBasic">
        <div class="w-1/3 flex justify-start">
          <a (click)="navigateBack()" class="cursor-pointer">
            <svg-icon
              class="inline-block"
              [svgStyle]="{ 'width.px': 22, 'height.px': 20 }"
              src="/assets/icons/arrow-left.svg"
            ></svg-icon>
          </a>
        </div>

        <div class="w-1/3 flex justify-center">
          <a class="cursor-pointer" [routerLink]="'/' | localize">
            <img class="cm-menu-logo" src="/assets/cm-logo.svg" alt="Coinmate" />
          </a>
        </div>
        <div class="w-1/3 flex justify-end">
          <app-language-switch [type]="languageSwitchTypeEnum.menu"></app-language-switch>
        </div>
      </ng-container>

      <!-- public menu -->
      <ng-container *ngIf="!isSecured && !isBasic">
        <div class="navigation navigation-{{ currentLang }}" [ngClass]="{ '!block open': !isClosed }">
          <div class="flex items-center">
            <a [routerLink]="'/' | localize" class="cursor-pointer desktop-only mr-[48px]">
              <img class="cm-menu-logo" src="/assets/cm-logo.svg" alt="Coinmate" />
            </a>

            <ul class="menu" *ngIf="appState !== 'verification'">
              <ng-container *ngFor="let link of menuLinks">
                <li
                  *ngIf="((loggedIn$ | async) === true && !link.hideWhenLogged) || (loggedIn$ | async) === false"
                  class="{{ link.czOnly ? 'cz-only' : '' }} {{ link.enOnly ? 'en-only' : '' }}"
                >
                  <app-menu-link [link]="link.url ? (link.url | localize) : undefined" [children]="link.subMenu">{{
                    link.name | translate
                  }}</app-menu-link>
                </li>
              </ng-container>
              <li>
                <app-menu-link
                  *ngIf="(loggedIn$ | async) === true && appState !== 'verification'"
                  [link]="'/quick-trade' | localize"
                  icon="/assets/icons/icon-quick-trade.svg"
                >
                  {{ 'shared.menu.buy-sell' | translate }}
                </app-menu-link>
              </li>
            </ul>
          </div>
          <!-- when not logged in -->
          <div *ngIf="(loggedIn$ | async) === false" class="flex items-center flex-wrap lg:flex-nowrap">
            <a (click)="navigate('/login')" class="cursor-pointer link-blue order-last lg:order-none !mb-0">{{
              'shared.common.login' | translate
            }}</a>
            <a (click)="navigate('/sign-up')" class="cursor-pointer button-blue desktop-only">{{
              'shared.menu.signup' | translate
            }}</a>
            <ul class="hidden md:block menu menu-icons !border-none !mb-0">
              <li>
                <app-language-switch [type]="languageSwitchTypeEnum.menu"></app-language-switch>
              </li>
              <li>
                <app-appearance-switcher class="ml-2 flex items-center"></app-appearance-switcher>
              </li>
            </ul>
          </div>

          <ul
            *ngIf="(loggedIn$ | async) === false"
            class="flex justify-center mt-6 menu-icons !border-none !mb-0 md:hidden"
          >
            <li>
              <app-language-switch [type]="languageSwitchTypeEnum.menuMobile"></app-language-switch>
            </li>
          </ul>
          <ng-container *ngTemplateOutlet="userAccountLinks"></ng-container>
        </div>
        <div class="mobile-only w-full flex flex-nowrap items-center justify-between">
          <a class="cursor-pointer" (click)="navigate('/')">
            <img class="cm-menu-logo" src="/assets/cm-logo-mobile.svg" alt="Coinmate" />
          </a>
          <div class="flex items-center">
            <a
              *ngIf="(loggedIn$ | async) === true && appState !== 'verification'"
              [routerLink]="'/quick-trade' | localize"
              class="button-blue"
            >
              {{ 'shared.menu.buy-sell' | translate }}
            </a>
            <a *ngIf="(loggedIn$ | async) === false" (click)="navigate('/sign-up')" class="button-blue cursor-pointer">
              {{ 'shared.menu.signup' | translate }}
            </a>
            <ng-container *ngTemplateOutlet="burger"></ng-container>
          </div>
        </div>
      </ng-container>

      <!-- secured menu / always logged in -->
      <ng-container *ngIf="isSecured">
        <div class="navigation" [ngClass]="{ '!block open': !isClosed }" style="z-index: 5000">
          <div class="flex items-center flex-wrap lg:flex-nowrap">
            <a class="cursor-pointer desktop-only mr-[48px]" (click)="navigate(securedMenuLogoLink)">
              <img class="cm-menu-logo" src="/assets/cm-logo-short-secured.svg" alt="Coinmate" />
            </a>
            <ul class="menu" *ngIf="appState !== 'verification'">
              <li>
                <app-menu-link [link]="'/quick-trade' | localize" icon="/assets/icons/icon-quick-trade.svg">
                  {{ 'shared.menu.buy-sell' | translate }}
                </app-menu-link>
              </li>
              <li>
                <app-menu-link [link]="'/trade' | localize">{{ 'shared.menu.trade' | translate }}</app-menu-link>
              </li>
              <li>
                <app-menu-link [link]="'/recurring-buy' | localize">{{
                  'shared.menu.recurring-buy' | translate
                }}</app-menu-link>
              </li>
            </ul>
            <ul *ngIf="appState !== 'verification'" class="menu block lg:!hidden">
              <ng-container *ngIf="accountList.length > 1">
                <ng-container *ngTemplateOutlet="accountsList"></ng-container>
              </ng-container>
              <li>
                <a [routerLink]="'/open-orders' | localize" class="menu-link">{{
                  'submenu.open-orders' | translate
                }}</a>
              </li>
            </ul>
          </div>
          <ng-container *ngTemplateOutlet="userAccountLinks"></ng-container>
        </div>
        <div class="mobile-only w-full flex flex-nowrap items-center justify-between">
          <a class="cursor-pointer" (click)="navigate(securedMenuLogoLink)">
            <img class="cm-menu-logo" src="/assets/cm-logo-mobile.svg" alt="Coinmate" />
          </a>
          <div class="flex items-center">
            <a *ngIf="appState !== 'verification'" [routerLink]="'/quick-trade' | localize" class="button-blue">{{
              'shared.menu.buy-sell' | translate
            }}</a>
            <ng-content *ngTemplateOutlet="burger"></ng-content>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="isSecured">
    <div class="context-bar" *ngIf="appState !== 'verification'">
      <div class="content">
        <div class="part w-full lg:w-auto lg:pr-4 lg:flex-1" *ngIf="isTradingPlatform">
          <app-context-trade-currency-bar></app-context-trade-currency-bar>
        </div>
        <div class="part qt-bar-and-account-change">
          <ng-container *ngIf="isQuickTrade">
            <app-context-currency-bar></app-context-currency-bar>
          </ng-container>
          <ng-container *ngIf="accountList.length > 1">
            <ul class="menu">
              <ng-container *ngTemplateOutlet="accountsList"></ng-container>
            </ul>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!isSecured && isTradingPlatform">
    <div class="context-bar">
      <div class="content">
        <div class="part justify-end">
          <app-context-trade-currency-bar></app-context-trade-currency-bar>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #accountsList>
    <li class="account-change" [ngClass]="{ disabled: accountChangeDisabled }">
      <ng-container *ngIf="!accountChangeDisabled; else accountChangeAlert">
        <a (click)="toggleAccounts($event)" class="menu-link">
          <ng-container *ngTemplateOutlet="accountsListTrigger"></ng-container>
        </a>
        <ul *ngIf="showAccounts" class="submenu">
          <li *ngFor="let account of accountList">
            <a (click)="changeSelectedAccount(account.id)" class="submenu-link">
              {{ account.name | translate }}
            </a>
          </li>
        </ul>
      </ng-container>
    </li>
  </ng-template>

  <ng-template #accountChangeAlert>
    <app-dropdown [dropdownClass]="'w-full'" [popoverClass]="'md:min-w-[348px]'">
      <button class="menu-link" dropdown-trigger>
        <ng-container *ngTemplateOutlet="accountsListTrigger"></ng-container>
      </button>

      <div class="text-large mb-3">
        <strong>{{ 'shared.menu.account-change-alert.title' | translate }}</strong>
      </div>
      <p class="mb-5">{{ 'shared.menu.account-change-alert.description' | translate }}</p>
      <button class="button-blue">{{ 'shared.menu.account-change-alert.button' | translate }}</button>
    </app-dropdown>
  </ng-template>

  <ng-template #accountsListTrigger>
    {{ selectedAccount?.name ?? '' | translate }}
    <svg-icon
      *ngIf="!this.showAccounts"
      class="inline-block"
      [svgStyle]="{ 'width.px': 12, 'height.px': 7 }"
      src="/assets/icons/arrow-down.svg"
    ></svg-icon>
    <svg-icon
      *ngIf="this.showAccounts"
      class="inline-block"
      [svgStyle]="{ 'width.px': 12, 'height.px': 7 }"
      src="/assets/icons/arrow-up.svg"
    ></svg-icon>
  </ng-template>

  <ng-template #userAccountLinks>
    <div *ngIf="(loggedIn$ | async) === true" class="flex items-center flex-wrap lg:flex-nowrap">
      <app-menu-link [link]="'/transfers' | localize">
        {{ 'shared.menu.deposit-withdrawal' | translate }}
      </app-menu-link>
      <ul class="ml-2 menu menu-icons">
        <li *ngIf="appState !== 'verification'">
          <app-menu-icon-link [link]="'/portfolio' | localize" [tooltip]="'submenu.portfolio' | translate">
            <svg-icon svgClass="icon" src="/assets/icons/icon-portfolio.svg"></svg-icon>
          </app-menu-icon-link>
        </li>
        <li *ngIf="appState !== 'verification'">
          <app-menu-icon-link [link]="'/transaction-history' | localize" [tooltip]="'shared.menu.history' | translate">
            <svg-icon svgClass="icon" src="/assets/icons/icon-history.svg"></svg-icon>
          </app-menu-icon-link>
        </li>
        <li>
          <app-menu-icon-link [link]="'/customer-support' | localize" [tooltip]="'messages-notifications' | translate">
            <svg-icon svgClass="icon" src="/assets/icons/icon-life-ring.svg"></svg-icon>
          </app-menu-icon-link>
        </li>
        <li>
          <app-menu-icon-link (click)="toggleAccountMenu($event)" [tooltip]="'user-account' | translate">
            <svg-icon svgClass="icon" src="/assets/icons/icon-user.svg"></svg-icon>
            <div content>
              <span class="link-with-icon-text">
                <ng-container *ngIf="(userInfo$ | async)?.firstName">
                  {{ (userInfo$ | async)?.firstName }} {{ (userInfo$ | async)?.lastName }}
                </ng-container>
                <ng-container *ngIf="!(userInfo$ | async)?.firstName">
                  {{ (userInfo$ | async)?.email }}
                </ng-container>
              </span>
              <span class="arrow">
                <svg-icon
                  class="inline-block"
                  [svgStyle]="{ 'width.px': 12, 'height.px': 7 }"
                  src="/assets/icons/arrow-down.svg"
                ></svg-icon>
                <svg-icon
                  class="hidden"
                  [svgStyle]="{ 'width.px': 12, 'height.px': 7 }"
                  src="/assets/icons/arrow-up.svg"
                ></svg-icon>
              </span>
            </div>
          </app-menu-icon-link>
          <ul class="submenu" *ngIf="showAccountMenu">
            <li *ngFor="let link of userMenuLinks">
              <a [routerLink]="link.url | localize" class="submenu-link">{{ link.name | translate }}</a>
            </li>
            <li
              class="!hidden lg:!block"
              [ngClass]="{
                'submenu-item-border': userMenuLinks.length
              }"
            >
              <a (click)="logout()" class="submenu-link cursor-pointer">
                <span
                  >{{ 'shared.menu.logout' | translate }}
                  <svg-icon
                    class="inline-block"
                    [svgStyle]="{ 'width.px': 14, 'height.px': 12 }"
                    src="/assets/icons/arrow-right.svg"
                  ></svg-icon
                ></span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
      <app-appearance-switcher class="ml-2"></app-appearance-switcher>
      <a (click)="logout()" class="link-logout">
        <span>
          {{ 'shared.menu.logout' | translate }}
          <svg-icon
            class="inline-block"
            [svgStyle]="{ 'width.px': 14, 'height.px': 12 }"
            src="/assets/icons/arrow-right.svg"
          ></svg-icon>
        </span>
      </a>
    </div>
  </ng-template>

  <ng-template #burger>
    <div class="hamburger">
      <button class="text-grey w-[29px] h-[25px] relative" (click)="toggleMenu()">
        <span class="sr-only">Open main menu</span>
        <div class="block w-6 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <span
            aria-hidden="true"
            class="block absolute h-[2px] w-[20px] bg-cm-blue-500 transform transition duration-500 ease-in-out"
            [ngClass]="{ 'rotate-45': !isClosed, ' -translate-y-2': isClosed }"
          ></span>
          <span
            aria-hidden="true"
            class="block absolute h-[2px] w-[20px] bg-cm-blue-500 transform transition duration-500 ease-in-out"
            [ngClass]="{ 'opacity-0': !isClosed }"
          ></span>
          <span
            aria-hidden="true"
            class="block absolute h-[2px] w-[20px] bg-cm-blue-500 transform transition duration-500 ease-in-out"
            [ngClass]="{ '-rotate-45': !isClosed, '!w-[20px]': !isClosed, ' translate-y-2': isClosed }"
          ></span>
        </div>
      </button>
    </div>
  </ng-template>
</header>
