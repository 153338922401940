import { Injectable, OnDestroy } from '@angular/core';
import { TimeagoIntl } from 'ngx-timeago';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { supportedLanguages } from '@app-shared/const/locales.const';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeCs from '@angular/common/locales/cs';
import { BehaviorSubject, firstValueFrom, Observable, Subject } from 'rxjs';
import {
  filter,
  switchMap,
  takeUntil,
  distinctUntilChanged,
  map,
  take,
  concatMap,
  withLatestFrom,
  tap,
} from 'rxjs/operators';
import { UserProfileControllerService } from '@app/generated/services/user-profile-controller.service';
import { GuiParams } from '@app/shared/store/gui-params/gui-params-facade.service';
import { UserLocaleDto } from '@app/generated/models/user-locale-dto';
import { Router } from '@angular/router';

type Language = 'en' | 'cs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService implements OnDestroy {
  readonly currentLanguage$: Observable<string>;
  private readonly _currentLanguage$: Subject<string>;
  private unsubscribe$ = new Subject<void>();

  loggedIn$ = this.guiParams.loggedIn$;
  guiParams$ = this.guiParams.guiParams$;

  constructor(
    private translate: TranslateService,
    private localize: LocalizeRouterService,
    private router: Router,
    private userProfileService: UserProfileControllerService,
    private timeAgoIntl: TimeagoIntl,
    private guiParams: GuiParams,
  ) {
    this._currentLanguage$ = new BehaviorSubject<string>(translate.currentLang);
    this.currentLanguage$ = this._currentLanguage$;
  }

  init() {
    this.translate.onLangChange.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (event: LangChangeEvent) => {
        this._currentLanguage$.next(event.lang);
        this.setLanguageTimeAgo(event.lang);
      },
      () => {
        // TODO DTP-5035
      },
    );

    // Handle initial language setup and changes for both logged-in and non-logged-in users
    const initialParams$ = this.guiParams.getCurrentAndSaveToStore();
    const ongoingParams$ = this.guiParams$.pipe(takeUntil(this.unsubscribe$));

    initialParams$
      .pipe(
        take(1),
        filter((params) => !!params.userLocale?.language),
        map((params) => params.userLocale!.language.toLowerCase() as Language),
      )
      .subscribe((language) => {
        this.changeLanguage(language);
      });

    ongoingParams$
      .pipe(
        withLatestFrom(this._currentLanguage$),
        filter(([params]) => !!params.userLocale?.language),
        map(([params, currentLanguage]) => [params.userLocale!.language.toLowerCase(), currentLanguage]),
        filter(([language, currentLanguage]) => language !== currentLanguage),
        map(([language]) => language as Language),
        distinctUntilChanged(),
      )
      .subscribe((language) => {
        this.changeLanguage(language);
      });

    registerLocaleData(localeEn, 'en');
    registerLocaleData(localeCs, 'cs');
  }

  private updateRouteLanguage(newLang: Language) {
    const currentUrl = this.router.url;
    const langRegex = /^\/(cs|en)(\/|$)/;

    if (langRegex.test(currentUrl)) {
      // If the URL already contains a language prefix, replace it
      const newUrl = currentUrl.replace(langRegex, `/${newLang}$2`);
      this.router.navigateByUrl(newUrl);
    } else {
      // If there's no language prefix, add it
      const newUrl = `/${newLang}${currentUrl}`;
      this.router.navigateByUrl(newUrl);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  isCurrentLanguage(language: string): boolean {
    return this.translate.currentLang === language;
  }

  getCurrentLanguage(): string {
    return this.translate.currentLang;
  }

  async changeLanguage(language: Language): Promise<void> {
    if (this.isCurrentLanguage(language)) {
      return;
    }

    try {
      await this.localize.changeLanguage(language);
      await firstValueFrom(this.translate.use(language));
      this.updateRouteLanguage(language);
      this._currentLanguage$.next(language);
    } catch (error) {}
  }

  changeAndSaveLanguage(language: Language): void {
    const upperCaseLanguage = language.toUpperCase() as UserLocaleDto['language'];
    this.guiParams.loggedIn$
      .pipe(
        take(1),
        filter((value) => value),
        concatMap(() =>
          this.userProfileService.changeLanguageRequestUsingPost({ lang: upperCaseLanguage }).pipe(take(1)),
        ),
        concatMap(() => this.guiParams.getCurrentAndSaveToStore(true).pipe(take(1))),
      )
      .subscribe();
  }

  setLanguageTimeAgo(language: string) {
    const { timeAgoStrings } = supportedLanguages.find((lang) => lang.key === language) || supportedLanguages[0];

    this.timeAgoIntl.strings = timeAgoStrings;
    this.timeAgoIntl.changes.next();
  }
}
