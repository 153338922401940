import { Component, Input, Renderer2 } from '@angular/core';
import { LocalizedRouterService } from '@app/shared/services/localized-router.service';

@Component({
  selector: 'app-menu-link',
  templateUrl: './menu-link.component.html',
  styleUrls: ['./menu-link.component.scss'],
})
export class MenuLinkComponent {
  @Input() link: string | undefined | string[];
  @Input() icon: string | undefined;
  @Input() children: any[] | undefined = [];
  showSubMenu: boolean = false;
  private globalClickListener: (() => void | undefined) | undefined;

  constructor(public router: LocalizedRouterService, private renderer: Renderer2) {}

  toggleMenu() {
    if (window.innerWidth > 1100) {
      return;
    }

    this.showSubMenu = !this.showSubMenu;
    const html = document.getElementsByTagName('html')[0];
    if (!this.showSubMenu) html.classList.add('mobile-menu-shown');
    else html.classList.remove('mobile-menu-shown');
  }

  toggleSubMenu(event: Event) {
    if (!this.children || this.children?.length === 0) return;
    event.stopPropagation();
    this.showSubMenu = !this.showSubMenu;
    // listen for click events on the document
    this.globalClickListener = this.renderer.listen('document', 'click', () => {
      this.showSubMenu = false;
      if (this.globalClickListener) this.globalClickListener(); // stop listening to click events
    });
  }
}
