<ng-container *ngIf="text; else content">
  <div class="tooltip-group group" #tooltipTarget>
    <ng-container *ngTemplateOutlet="content"></ng-container>
    <div
      class="tooltip-content {{ tooltipClass }} {{ appearance }} {{ displayDot ? 'dot' : '' }}"
      [ngClass]="{
        'position-top': tooltipPosition === tooltipPositionEnum.top,
        'position-bottom': tooltipPosition === tooltipPositionEnum.bottom,
        'position-left': tooltipPosition === tooltipPositionEnum.left,
        'position-right': tooltipPosition === tooltipPositionEnum.right
      }"
      [style.left.px]="left"
      [style.top.px]="top"
      #tooltipContent
    >
      <span [innerHTML]="text"></span>
    </div>
  </div>
</ng-container>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
