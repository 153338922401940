import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DropdownPopoverService } from '@app/shared/components/dropdown/dropdown-popover.service';

@Component({
  selector: 'button[dropdown-trigger]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dropdown-trigger.component.html',
  host: {
    '[class]': 'getHostClasses()',
    '(click)': 'togglePopover($event)',
  },
})
export class DropdownTriggerComponent {
  @Input() facet?: 'link';
  @Input() withChevron?: string;

  constructor(private popoverService: DropdownPopoverService) {}

  getHostClasses(): string {
    const classes = ['dropdown-trigger'];

    if (this.facet) {
      classes.push(`dropdown-trigger-${this.facet}`);
    }

    if (this.withChevron) {
      classes.push('dropdown-trigger-with-chevron');
    }

    return classes.join(' ');
  }

  togglePopover(event: Event) {
    this.popoverService.toggle();
  }
}
