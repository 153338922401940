import { Component, OnInit } from '@angular/core';
import { GuiParams } from '@app/shared/store/gui-params/gui-params-facade.service';
import { Router } from '@angular/router';
import { BehaviorSubject, interval, Observable, of } from 'rxjs';
import { delay, distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { FooterColumn } from '@app/shared/api/footer.api';
import { Paragraph } from '@app/shared/components/paragraph/paragraph.component';
import { LanguageSwitchTypeEnum } from '@app/shared/components/language-switch/language-switch.component';
import { CurrencyPairWithStatsDto } from '@app/generated/models/currency-pair-with-stats-dto';
import { CurrencyDto } from '@app/generated/models/currency-dto';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { TooltipPosition } from '@app/shared/components/tooltip/tooltip.enum';
import { PopoverPosition } from '@app/shared/components/dropdown/dropdown.enum';
import { CurrencyWithBalance } from '@app/shared/components/balances-table/balances-table.component';
import { BALANCES_TABLE_MOCK_DATA } from '@app/shared/components/components-overview/components-overview.mock.data';
import { MenuVerticalPopoverService } from '@app/shared/components/menu-vertical/menu-vertical-popover.service';
import { ModalService } from '@app/shared/components/modal/modal.service';
import { ConfirmationModalComponent } from '@app/shared/components/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-components-overview',
  templateUrl: './components-overview.component.html',
  styleUrls: ['./components-overview.component.scss'],
  providers: [MenuVerticalPopoverService], // TODO: Check if it is necessary
})
export class ComponentsOverviewComponent implements OnInit {
  showContent = false;

  coinmate: FooterColumn = {
    links: [
      { text: 'shared.footer.fees', routerLink: '/fees', link: null },
      { text: 'shared.footer.cryptocurrencies', routerLink: '/prices', link: null },
      { text: 'shared.menu.our-story', routerLink: '/our-story', link: null },
      { text: 'shared.footer.affiliate', routerLink: '/affiliate', link: null },
    ],
  };
  apiIntegrationP: Paragraph = {
    content: 'developers.apiIntegration.content',
  };
  apiForBanksP: Paragraph = {
    header: 'developers.apiForBanks.header',
    standalone: true,
    content: 'developers.apiForBanks.content',
  };
  filterOptions = [
    { value: 'BTC', active: false },
    { value: 'LTC', active: false },
    { value: 'CZK', active: true },
    { value: 'EUR', active: false },
  ];
  languageSwitchTypeEnum = LanguageSwitchTypeEnum;
  mockCurrencyPair: CurrencyPairWithStatsDto = {
    name: 'BTC_EUR',
    firstCurrency: 'BTC',
    secondCurrency: 'EUR',
    priceDecimalCount: 1,
    lastPrice: 350,
    changeIn24Hours: 20,
  };
  mockFromCurrency: CurrencyDto = {
    guiDecimalCount: 2,
    name: 'EUR',
    symbol: '€',
    displayName: 'EUR',
    virtual: false,
    active: true,
    depositActive: true,
    withdrawalActive: false,
  };

  protected readonly typographyTextDemo = 'Každý by měl vlastnit krypto';
  protected readonly loremIpsumText =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam et blandit nisi. Etiam sapien elit, tristique sed lacus a, aliquet varius lorem. Ut nibh libero, euismod eget interdum ac, viverra faucibus ex.';

  protected readonly appColoredCurrencyValueNegative = -92.891563;
  protected readonly appColoredCurrencyValuePositive = 92.891563;
  protected readonly appColoredCurrencyValueNearlyZero = 0.0000089;
  protected readonly appPercentChangeValueNegative = -92.89;
  protected readonly appPercentChangeValuePositive = 92.89;
  protected readonly appPercentChangeValueNearlyZero = 0.001;

  protected readonly accordionHeaderText = 'The quick brown fox jumps over the lazy dog.';
  protected readonly accordionBodyText =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';

  // region Balances table
  // @ts-ignore
  balancesTableData$: Observable<CurrencyWithBalance[]> = of(BALANCES_TABLE_MOCK_DATA);
  balancesTableStatusSubject$ = new BehaviorSubject<'pending' | 'loading'>('pending');
  balancesTableStatus$ = this.balancesTableStatusSubject$.pipe(
    delay(5000),
    switchMap((status) => {
      if (status === 'loading') {
        this.balancesTableStatusSubject$.next('pending');
      }
      return this.balancesTableStatusSubject$;
    }),
  );
  // endregion

  // region Progress bar
  progressBarValueGenerator$ = interval(2000).pipe(map(() => Math.floor(Math.random() * 101)));
  // endregion

  // region Data table
  dataTableStatusSubject$ = new BehaviorSubject<'pending' | 'loading'>('pending');
  dataTableData$ = new BehaviorSubject<any[]>([]);
  dataTableStatus$ = this.dataTableStatusSubject$.pipe(
    delay(5000),
    switchMap((status) => {
      if (status === 'loading') {
        this.dataTableStatusSubject$.next('pending');
      }
      return this.dataTableStatusSubject$;
    }),
    distinctUntilChanged(),
    tap((status) => {
      const currentData = this.dataTableData$.getValue();

      if (status === 'pending' || !currentData.length) {
        const currentData = this.dataTableData$.getValue();
        const newData = Array.from({ length: BALANCES_TABLE_MOCK_DATA.length }, (v, i) => ({
          id: i + 1,
          ...BALANCES_TABLE_MOCK_DATA[i],
        }));
        console.log('currentData', currentData);
        this.dataTableData$.next(currentData.concat(newData));
      }
    }),
  );
  dataTableColumnWidths = [180, 70, 125, 185, 185, 185, 90, 90];
  // endregion

  protected readonly tooltipPositionEnum = TooltipPosition;
  protected readonly popoverPositionEnum = PopoverPosition;

  constructor(
    private guiParams: GuiParams,
    private router: Router,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.guiParams.guiParams$
      .pipe(
        filter((guiParams) => guiParams.environment.length > 0),
        take(1),
      )
      .subscribe((guiParams) => {
        if (guiParams.environment === 'TEST') {
          this.showContent = true;
        } else {
          this.router.navigate(['/']);
        }
      });
  }

  showInfo() {
    const message = this.translateService.instant('quick-trade.main.error.otc');
    this.toastr.info(message);
  }

  showSuccess() {
    const message = this.translateService.instant('quick-trade.main.success.title');
    this.toastr.success(message);
  }

  showError() {
    const message = this.translateService.instant('quick-trade.main.error.otc');
    this.toastr.error(message);
  }

  showWarning() {
    const message = this.translateService.instant('quick-trade.main.error.otc');
    this.toastr.warning(message);
  }

  // region Balances table
  reloadBalancesTable() {
    this.balancesTableStatusSubject$.next('loading');
  }

  onAddFavorite(currency: CurrencyWithBalance) {
    alert(`Add favorite:\n${JSON.stringify(currency)}`);
  }

  onRemoveFavorite(currency: CurrencyWithBalance) {
    alert(`Remove favorite:\n${JSON.stringify(currency)}`);
  }
  // endregion

  // region Data table
  dataTableAddData() {
    this.dataTableStatusSubject$.next('loading');
  }

  dataTableOpenDetail(title: string, row: any) {
    alert(`${title}\nRow detail:\n${JSON.stringify(row)}`);
  }
  // endregion

  // region Confirmation dialog
  toggleConfirmationDialog() {
    this.modalService.toggleDynamicModal(
      'confirmationModal',
      ConfirmationModalComponent,
      {
        modalBody: this.translateService.instant('Do you really wish to cancel all open orders displayed below?'),
      },
      {
        confirm: () => {
          alert('Confirm');
        },
        cancel: () => {
          alert('Cancel');
        },
      },
    );
  }
  // endregion

  // region Checkbox
  toggleCheckbox(event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    console.log(`Is checked: ${isChecked}`);
  }
  // endregion
}
