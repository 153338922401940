import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromState from './store/shared.reducer';
import { RouterModule } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CookieService } from 'ngx-cookie-service';
import { InitService } from '@app-shared/services/init.service';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { AppCurrencySymbolPipe } from './pipes/app-currency-symbol.pipe';
import { AppFloorPriceObservablePipe } from './pipes/app-floor-price-observable.pipe';
import { AppCurrencyFloorPipe, AppFloorPipe } from './pipes/app-floor.pipe';
import { AppFloorPricePipe } from './pipes/app-floor-price.pipe';
import { AppCurrencyPairDisplayPipe } from './pipes/app-currency-pair-display.pipe';
import { PublicWithHeaderComponent } from '@app-shared/components/public-with-header/public-with-header.component';
import { ParagraphComponent } from './components/paragraph/paragraph.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { InputComponent } from './components/form/input/input.component';
import { AbstractComponent } from './components/form/abstract/abstract.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectComponent } from './components/form/select/select.component';
import { CheckboxComponent } from './components/form/checkbox/checkbox.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ParagraphWithImgComponent } from '@app-shared/components/paragraph-with-img/paragraph-with-img.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from '@app-shared/interceptors/error.interceptor';
import { CsrfInterceptor } from '@app-shared/interceptors/csrf.interceptor';
import { TextareaComponent } from '@app-shared/components/form/textarea/textarea.component';
import { DropdownDirective } from './directives/dropdown.directive';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { AppCurrencyPipe } from './pipes/app-currency.pipe';
import { AppToNumberPipe } from './pipes/app-to-number.pipe';
import { EffectsModule } from '@ngrx/effects';
import sharedEffects from '@app-shared/store/shared.effects';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NoDblClickDirective } from '@app-shared/directives/no-dbl-click.directive';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { SelectNewComponent } from '@app-shared/components/form/select_new/select.component';
import { StrapiTableComponent } from '@app/shared/components/strapi-table/strapi-table.component';
import { HComponent } from '@app-shared/components/h/h.component';
import { TextComponent } from '@app-shared/components/text/text.component';
import { ParagraphBoxComponent } from '@app-shared/components/paragraph-box/paragraph-box.component';
import { SeparatorComponent } from '@app-shared/components/separator/separator.component';
import { ImgComponent } from '@app-shared/components/img/img.component';
import { HalfToHalfComponent } from '@app-shared/components/half-to-half/half-to-half.component';
import { LeftDirective } from '@app-shared/components/half-to-half/directive/left.directive';
import { RightDirective } from '@app-shared/components/half-to-half/directive/right.directive';
import { ListComponent } from '@app-shared/components/list/list.component';
import { SliderComponent } from '@app-shared/components/slider/slider.component';
import { VideoComponent } from '@app-shared/components/video/video.component';
import { CookieConsentComponent } from '@app-shared/components/cookie-consent/cookie-consent.component';
import { AppStrapiLearningPipe } from '@app-shared/pipes/app-strapi-learning.pipe';
import { NavigationBarComponent } from '@app-shared/components/navigation-bar/navigation-bar.component';
import { LanguageSwitchComponent } from '@app/shared/components/language-switch/language-switch.component';
import { MenuComponent } from '@app/shared/components/menu/menu.component';
import { PhoneNumberComponent } from '@app/shared/components/form/phone-number/phone-number.component';
import { EmailComponent } from '@app/shared/components/form/email/email.component';
import { PasswordComponent } from '@app/shared/components/form/password/password.component';
import { VerificationDocumentComponent } from '@app/shared/components/form/verification-document/verification-document.component';
import { ActionButtonComponent } from '@app/shared/components/form/action-button/action-button.component';
import { AlertMessageComponent } from '@app/shared/components/alert-message/alert-message.component';
import { LinkPairComponent } from '@app/shared/components/link-pair/link-pair.component';
import { DoubleSectionPageTemplateModule } from '@app/shared/components/double-section-page-template/double-section-page-template.module';
import { TextareaNewComponent } from './components/form/textarea-new/textarea-new.component';
import { FooterComponent } from '@app/shared/components/footer/footer.component';
import { FooterLinkComponent } from '@app/shared/components/footer/footer-link/footer-link.component';
import { CurrenciesTableComponent } from './components/currencies-table/currencies-table.component';
import { HorizontalScrollDirective } from '@app/shared/directives/horizontal-scroll.directive';
import {
  ColoredCurrencyValueTextComponent,
  ColoredPercentChangeTextComponent,
} from '@app/shared/components/colored-value-text/colored-value-text.component';
import { LoggedButtonComponent } from '@app/shared/components/logged-button/logged-button.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { DragToScrollDirective } from './directives/drag-to-scroll.directive';
import { AppSupportPipe } from '@app/shared/pipes/app-support.pipe';
import { LoadingComponent } from '@app/shared/components/loading/loading.component';
import { DecimalNumberPipe } from './pipes/decimal-number.pipe';
import { LoggedLinkPairComponent } from './components/logged-link-pair/logged-link-pair.component';
import { AppIsCurrencyLowerPipe } from '@app/shared/pipes/app-is-currency-lower.pipe';
import { TranslateTextComponent } from '@app/shared/components/translate-text/translate-text.component';
import { TooltipContainerComponent } from '@app/shared/components/tooltip-container/tooltip-container.component';
import { CellDirective, FooterCellDirective, HeaderCellDirective } from '@app/shared/components/table/cell';
import {
  CellDefDirective,
  FooterCellDefDirective,
  HeaderCellDefDirective,
} from '@app/shared/components/table/cell-def';
import { ColumnDefDirective } from '@app/shared/components/table/column';
import { FooterRowDefDirective, HeaderRowDefDirective, RowDefDirective } from '@app/shared/components/table/row-def';
import {
  FooterRowComponent,
  HeaderRowComponent,
  NoDataRowDirective,
  RowComponent,
} from '@app/shared/components/table/row';
import { RecycleRowsDirective, TableComponent } from '@app/shared/components/table/table';
import { CdkTableModule } from '@angular/cdk/table';
import { CopyToClipboardComponent } from '@app/shared/components/copy-to-clipboard/copy-to-clipboard.component';
import { CurrencyAmountComponent } from '@app/shared/components/menu/currency-amount/currency-amount.component';
import { CurrencyPairComponent } from '@app/shared/components/menu/currency-pair/currency-pair.component';
import { CutZerosPipe } from '@app/shared/pipes/cut-zeros.pipe';
import { AccountNumberPipe } from '@app/shared/pipes/account-number.pipe';
import { CurrencyPairSelectComponent } from '@app/shared/components/menu/currency-pair-select/currency-pair-select.component';
import { BenefitComponent } from '@app/shared/components/benefit/benefit.component';
import { RadioComponent } from '@app/shared/components/form/radio/radio.component';
import { CurrencyInputComponent } from '@app/shared/components/form/currency-input/currency-input.component';
import { QtCurrencyInputComponent } from '@app/shared/components/form/qt-currency-input/qt-currency-input.component';
import { OnlyNumbersTextInputDirective } from '@app/shared/directives/only-numbers-text-input.directive';
import { BaseModalComponent } from './components/modal/base-modal/base-modal.component';
import { SearchComponent } from './components/search/search/search.component';
import { ContextCurrencyBarComponent } from './components/bar/context-currency-bar/context-currency-bar.component';
import { ContextCurrencyModalComponent } from './components/bar/context-currency-modal/context-currency-modal.component';
import { MessageComponent } from '@app/shared/components/message/message.component';
import { ScrolledToBottomDirective } from '@app/shared/directives/scrolled-to-bottom.directive';
import { AppCurrencyAmountPipe } from '@app/shared/pipes/app-currency-amount.pipe';
import { ReplaceUnderscorePipe } from '@app/shared/pipes/replace-underscore.pipe';
import { CurrencyPairSelectorComponent } from './components/currency-pair/currency-pair-selector/currency-pair-selector.component';
import { MultiChoiceButtonBarComponent } from './components/multi-choice-button-bar/multi-choice-button-bar.component';
import { CurrencyPairSelectorModalComponent } from './components/currency-pair/currency-pair-selector-modal/currency-pair-selector-modal.component';
import { ConfirmationModalComponent } from '@app/shared/components/confirmation-modal/confirmation-modal.component';
import { CustomConfirmationDialogComponent } from './components/custom-confirmation-dialog/custom-confirmation-dialog.component';
import { PhoneNumberDirective } from './directives/phone-number.directive';
import { PhoneNumberInputComponent } from '@app/shared/components/phone-number-input/phone-number-input.component';
import { PaymentMethodComponent } from '@app/authenticated/quick-trade/components/payment-method/payment-method.component';
import { ControlErrorMessagePipe } from '@app/shared/pipes/control-error-message.pipe';
import { TimePipe } from '@app/shared/pipes/time.pipe';
import { WebOtpDirective } from '@app/shared/directives/web-otp.directive';
import { OtpInputComponent } from '@app/shared/components/otp-input/otp-input.component';
import { CodeInputModule } from 'angular-code-input';
import { SwitchComponent } from './components/form/switch/switch.component';
import { ComponentsOverviewComponent } from '@app/shared/components/components-overview/components-overview.component';
import { CountryInputComponent } from '@app/shared/components/country-input/country-input.component';
import { ImportantInformationComponent } from '@app/authenticated/deposits-withdrawals/components/important-information/important-information.component';
import { LimitsComponent } from '@app/authenticated/deposits-withdrawals/components/limits/limits.component';
import { PaymentOptionComponent } from '@app/authenticated/deposits-withdrawals/components/payment-option/payment-option.component';
import { TransactionStepComponent } from '@app/authenticated/deposits-withdrawals/components/transaction-step/transaction-step.component';
import { WalletInputComponent } from '@app/authenticated/deposits-withdrawals/components/wallet-input/wallet-input.component';
import { AmountInputComponent } from '@app/authenticated/deposits-withdrawals/components/amount-input/amount-input.component';
import { ConfirmInputComponent } from '@app/authenticated/deposits-withdrawals/components/confirm-input/confirm-input.component';
import { EasySelectInputComponent } from '@app/authenticated/deposits-withdrawals/components/easy-select-input/easy-select-input.component';
import { DwModalComponent } from '@app/authenticated/deposits-withdrawals/components/dw-modal/dw-modal.component';
import { DwTooltipComponent } from '@app/authenticated/deposits-withdrawals/components/dw-tooltip/dw-tooltip.component';
import { DwHeaderComponent } from '@app/authenticated/deposits-withdrawals/components/dw-header/dw-header.component';
import { DwErrorDisplayComponent } from '@app/authenticated/deposits-withdrawals/components/dw-error-display/dw-error-display.component';
import { DynamicStepperComponent } from '@app/authenticated/deposits-withdrawals/components/dynamic-stepper/dynamic-stepper.component';
import { CountdownTimerComponent } from '@app/shared/components/countdown-timer/countdown-timer.component';
import { TvChartContainerComponent } from '@app/shared/components/tv-chart-container/tv-chart-container.component';
import { ContextTradeCurrencyBarComponent } from './components/bar/context-trade-currency-bar/context-trade-currency-bar.component';
import { ContextTradeCurrencyModalComponent } from './components/bar/context-trade-currency-modal/context-trade-currency-modal.component';
import { AppCryptoPrecisionPipe } from '@app/shared/pipes/app-crypto-precision.pipe';
import { ThrottleClickDirective } from '@app/shared/directives/throttle-click-directive';
import { RecurringBuyUpsellComponent } from '@app/authenticated/deposits-withdrawals/components/recurring-buy-upsell/recurring-buy-upsell.component';
import { RecurringBuyUpsellModalComponent } from '@app/authenticated/deposits-withdrawals/components/recurring-buy-upsell-modal/recurring-buy-upsell-modal.component';
import { BtcUnitModalComponent } from '@app/authenticated/deposits-withdrawals/components/btc-unit-modal/btc-unit-modal.component';
import { AmountInputBtcLnComponent } from '@app/authenticated/deposits-withdrawals/components/amount-input-btc-ln/amount-input-btc-ln.component';
import { IconSpinnerComponent } from '@app/shared/components/icon-spinner/icon-spinner.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ImportantInformationLightningComponent } from '@app/authenticated/deposits-withdrawals/components/important-information-lightning/important-information.component';
import { CreatePriceAlertModalComponent } from '@app/authenticated/price-alerts/create-price-alert-modal/create-price-alert-modal.component';
import { SwitcherComponent } from './components/switcher/switcher.component';
import { RadioButtonsComponent } from './components/radio-buttons/radio-buttons.component';
import { ContextInfoBoxComponent } from './components/context-info-box/context-info-box.component';
import { CodeInfoComponent } from './components/code-info/code-info.component';
import { HelpIconComponent } from '@app/shared/components/help-icon/help-icon.component';
import { AppHumanFileSizePipe } from '@app/shared/pipes/app-human-file-size.pipe';
import { ProfileBaseModalComponent } from '@app/authenticated/profile/ui/components/profile-base-modal/profile-base-modal.component';
import { AppPercentagePipe, AppPercentageChangePipe } from '@app/shared/pipes/app-percentage.pipe';
import { MenuVerticalModule } from '@app/shared/components/menu-vertical/menu-vertical.module';
import { DropdownModule } from '@app/shared/components/dropdown/dropdown.module';
import { BalancesTableComponent } from './components/balances-table/balances-table.component';
import { WpPageContentComponent } from '@app/shared/components/wp-page-content/wp-page-content.component';
import { AnimatedCounterDirective } from './directives/animated-counter.directive';
import { AccordionModule } from '@app/shared/components/accordion/accordion.module';
import { SkeletonComponent } from '@app/shared/components/skeleton/skeleton.component';
import { ViewWithSidebarComponent } from '@app/shared/components/view-with-sidebar/view-with-sidebar.component';
import { ProfileNavigationComponent } from '@app/shared/components/profile-navigation/profile-navigation.component';
import { ProgressBarComponent } from '@app/shared/components/progress-bar/progress-bar.component';
import { MaskStringPipe } from './pipes/mask-string.pipe';
import { DataTableModule } from '@app/shared/components/data-table/data-table.module';
import { MenuLinkComponent } from '@app/shared/components/menu/menu-link/menu-link.component';
import { MenuIconLinkComponent } from '@app/shared/components/menu/menu-icon-link/menu-icon-link.component';
import { AppearanceSwitcherComponent } from '@app/shared/components/menu/appearance-switcher/appearance-switcher.component';

export const initializeApp = (injector: Injector) => (): Promise<any> =>
  new Promise<void>((resolve) => {
    const initService = injector.get(InitService);
    initService.init();
    resolve();
  });

@NgModule({
  declarations: [
    AppCurrencySymbolPipe,
    AppFloorPriceObservablePipe,
    AppFloorPipe,
    AppCurrencyFloorPipe,
    AppFloorPricePipe,
    AppCurrencyPairDisplayPipe,
    AppHumanFileSizePipe,
    PublicWithHeaderComponent,
    ParagraphComponent,
    SafeHtmlPipe,
    TimePipe,
    InputComponent,
    PhoneNumberComponent,
    EmailComponent,
    PasswordComponent,
    VerificationDocumentComponent,
    ActionButtonComponent,
    TextareaComponent,
    AbstractComponent,
    SelectComponent,
    SelectNewComponent,
    CheckboxComponent,
    ParagraphWithImgComponent,
    DropdownDirective,
    NoDblClickDirective,
    ThrottleClickDirective,
    OnlyNumbersTextInputDirective,
    UserAvatarComponent,
    AppCurrencyPipe,
    AppToNumberPipe,
    LocalizedDatePipe,
    AppCurrencyAmountPipe,
    ReplaceUnderscorePipe,
    HComponent,
    TextComponent,
    ParagraphBoxComponent,
    SeparatorComponent,
    ImgComponent,
    HalfToHalfComponent,
    LeftDirective,
    RightDirective,
    ListComponent,
    SliderComponent,
    VideoComponent,
    StrapiTableComponent,
    WpPageContentComponent,
    ViewWithSidebarComponent,
    CookieConsentComponent,
    AppStrapiLearningPipe,
    NavigationBarComponent,
    LanguageSwitchComponent,
    LinkPairComponent,
    MenuComponent,
    AlertMessageComponent,
    TextareaNewComponent,
    FooterComponent,
    FooterLinkComponent,
    CurrenciesTableComponent,
    HorizontalScrollDirective,
    ColoredPercentChangeTextComponent,
    LoggedButtonComponent,
    LoadingComponent,
    IconSpinnerComponent,
    TooltipComponent,
    DragToScrollDirective,
    AppSupportPipe,
    DecimalNumberPipe,
    LoggedLinkPairComponent,
    AppIsCurrencyLowerPipe,
    TranslateTextComponent,
    TooltipContainerComponent,
    CellDefDirective,
    CellDirective,
    HeaderCellDefDirective,
    HeaderCellDirective,
    FooterCellDefDirective,
    FooterCellDirective,
    ColumnDefDirective,
    RowDefDirective,
    RowComponent,
    HeaderRowDefDirective,
    HeaderRowComponent,
    FooterRowDefDirective,
    FooterRowComponent,
    NoDataRowDirective,
    RecycleRowsDirective,
    TableComponent,
    ProfileNavigationComponent,
    CopyToClipboardComponent,
    CurrencyAmountComponent,
    CurrencyPairComponent,
    CutZerosPipe,
    AccountNumberPipe,
    AppCryptoPrecisionPipe,
    ControlErrorMessagePipe,
    CurrencyPairSelectComponent,
    MenuLinkComponent,
    MenuIconLinkComponent,
    AppearanceSwitcherComponent,
    BenefitComponent,
    RadioComponent,
    CurrencyInputComponent,
    QtCurrencyInputComponent,
    BaseModalComponent,
    ProfileBaseModalComponent,
    SearchComponent,
    ContextCurrencyBarComponent,
    ContextCurrencyModalComponent,
    MessageComponent,
    CurrencyPairSelectorComponent,
    MultiChoiceButtonBarComponent,
    CurrencyPairSelectorModalComponent,
    ConfirmationModalComponent,
    CustomConfirmationDialogComponent,
    PhoneNumberDirective,
    PhoneNumberInputComponent,
    PaymentMethodComponent,
    WebOtpDirective,
    OtpInputComponent,
    SwitchComponent,
    ComponentsOverviewComponent,
    CountryInputComponent,
    ImportantInformationComponent,
    ImportantInformationLightningComponent,
    LimitsComponent,
    PaymentOptionComponent,
    TransactionStepComponent,
    WalletInputComponent,
    AmountInputComponent,
    AmountInputBtcLnComponent,
    ConfirmInputComponent,
    EasySelectInputComponent,
    DwModalComponent,
    DwTooltipComponent,
    CreatePriceAlertModalComponent,
    RecurringBuyUpsellComponent,
    RecurringBuyUpsellModalComponent,
    BtcUnitModalComponent,
    DwHeaderComponent,
    DwErrorDisplayComponent,
    DynamicStepperComponent,
    CountdownTimerComponent,
    TvChartContainerComponent,
    ContextTradeCurrencyBarComponent,
    ContextTradeCurrencyModalComponent,
    AppCryptoPrecisionPipe,
    SwitcherComponent,
    RadioButtonsComponent,
    LoaderComponent,
    HelpIconComponent,
    ContextInfoBoxComponent,
    CodeInfoComponent,
    AppPercentagePipe,
    AppPercentageChangePipe,
    BalancesTableComponent,
    ColoredCurrencyValueTextComponent,
    AnimatedCounterDirective,
    MaskStringPipe,
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromState.stateFeatureKey, fromState.reducers, { metaReducers: fromState.metaReducers }),
    EffectsModule.forFeature([...sharedEffects]),
    RouterModule,
    TranslateModule.forChild(),
    LocalizeRouterModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    NgScrollbarModule,
    RouterModule,
    CdkTableModule,
    AngularSvgIconModule,
    CodeInputModule,
    AngularSvgIconModule,
    AsyncPipe,

    DoubleSectionPageTemplateModule,
    MenuVerticalModule,
    DropdownModule,
    AccordionModule,
    SkeletonComponent,
    ProgressBarComponent,
    ScrolledToBottomDirective,
    DataTableModule,
  ],
  providers: [
    CookieService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [Injector],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CsrfInterceptor,
      multi: true,
    },
  ],
  exports: [
    FontAwesomeModule,
    RouterModule,
    TranslateModule,
    AngularSvgIconModule,
    FormsModule,
    ReactiveFormsModule,
    LocalizeRouterModule,
    AppCurrencyPairDisplayPipe,
    AppCurrencySymbolPipe,
    AppFloorPipe,
    AppCurrencyFloorPipe,
    AppFloorPricePipe,
    AppCryptoPrecisionPipe,
    AppHumanFileSizePipe,
    ControlErrorMessagePipe,
    AppFloorPriceObservablePipe,
    PublicWithHeaderComponent,
    SafeHtmlPipe,
    TimePipe,
    ParagraphWithImgComponent,
    ParagraphComponent,
    InputComponent,
    TextareaComponent,
    SelectComponent,
    CheckboxComponent,
    NoDblClickDirective,
    ThrottleClickDirective,
    LocalizedDatePipe,
    HComponent,
    TextComponent,
    ParagraphBoxComponent,
    SeparatorComponent,
    ImgComponent,
    HalfToHalfComponent,
    LeftDirective,
    RightDirective,
    ListComponent,
    SliderComponent,
    VideoComponent,
    StrapiTableComponent,
    WpPageContentComponent,
    ViewWithSidebarComponent,
    CookieConsentComponent,
    AppStrapiLearningPipe,
    SelectNewComponent,
    NavigationBarComponent,
    PhoneNumberComponent,
    EmailComponent,
    PasswordComponent,
    VerificationDocumentComponent,
    ActionButtonComponent,
    LinkPairComponent,
    MenuComponent,
    AlertMessageComponent,
    FooterComponent,
    FooterLinkComponent,
    TextareaNewComponent,
    CurrenciesTableComponent,
    HorizontalScrollDirective,
    ColoredPercentChangeTextComponent,
    LoggedButtonComponent,
    LoadingComponent,
    IconSpinnerComponent,
    DragToScrollDirective,
    AppSupportPipe,
    TooltipComponent,
    LoggedLinkPairComponent,
    AppIsCurrencyLowerPipe,
    TranslateTextComponent,
    TooltipContainerComponent,
    TableComponent,
    CellDefDirective,
    CellDirective,
    HeaderCellDefDirective,
    HeaderCellDirective,
    FooterCellDefDirective,
    FooterCellDirective,
    ColumnDefDirective,
    RowDefDirective,
    RowComponent,
    HeaderRowDefDirective,
    HeaderRowComponent,
    FooterRowDefDirective,
    FooterRowComponent,
    NoDataRowDirective,
    RecycleRowsDirective,
    OnlyNumbersTextInputDirective,
    ProfileNavigationComponent,
    CopyToClipboardComponent,
    BenefitComponent,
    RadioComponent,
    CurrencyInputComponent,
    DecimalNumberPipe,
    QtCurrencyInputComponent,
    BaseModalComponent,
    ProfileBaseModalComponent,
    SearchComponent,
    ContextCurrencyBarComponent,
    MessageComponent,
    AppCurrencyAmountPipe,
    ReplaceUnderscorePipe,
    MenuLinkComponent,
    MenuIconLinkComponent,
    AppearanceSwitcherComponent,
    CurrencyPairSelectComponent,
    CurrencyPairSelectorComponent,
    ConfirmationModalComponent,
    CustomConfirmationDialogComponent,
    PhoneNumberInputComponent,
    PaymentMethodComponent,
    WebOtpDirective,
    OtpInputComponent,
    SwitchComponent,
    ComponentsOverviewComponent,
    CountryInputComponent,
    LimitsComponent,
    ImportantInformationComponent,
    ImportantInformationLightningComponent,
    PaymentOptionComponent,
    TransactionStepComponent,
    WalletInputComponent,
    AmountInputComponent,
    AmountInputBtcLnComponent,
    ConfirmInputComponent,
    EasySelectInputComponent,
    DwModalComponent,
    DwTooltipComponent,
    CreatePriceAlertModalComponent,
    RecurringBuyUpsellComponent,
    RecurringBuyUpsellModalComponent,
    BtcUnitModalComponent,
    DwHeaderComponent,
    DwErrorDisplayComponent,
    DynamicStepperComponent,
    CountdownTimerComponent,
    TvChartContainerComponent,
    AppCryptoPrecisionPipe,
    LoaderComponent,
    SwitcherComponent,
    RadioButtonsComponent,
    ContextInfoBoxComponent,
    CodeInfoComponent,
    HelpIconComponent,
    AppPercentagePipe,
    AppPercentageChangePipe,
    DoubleSectionPageTemplateModule,
    MenuVerticalModule,
    DropdownModule,
    AccordionModule,
    BalancesTableComponent,
    ColoredCurrencyValueTextComponent,
    AnimatedCounterDirective,
    MaskStringPipe,
    DataTableModule,
  ],
})
export class SharedModule {}
