<button
  [type]="type"
  (click)="!waiting && action.emit()"
  [disabled]="disabled || waiting"
  class="cm-button {{ appearance }}"
  [ngClass]="{
    'cm-button-small': size === 'small',
    'cm-button-primary': facet === 'primary',
    'cm-button-tertiary': facet === 'tertiary',
    'cm-button-secondary': facet === 'secondary',
    'cm-button-danger': facet === 'danger',
    'cm-button-link': facet === 'link',
    '!w-full': isFullWidth
   }"
>
  <ng-container *ngIf="!waiting; else spinner">
    <div class="icon">
      <ng-content select="[icon]"></ng-content>
    </div>
    <ng-container *ngIf="label">
      <span class="cm-button-label">{{ label | translate }}</span>
    </ng-container>
    <ng-content></ng-content>
  </ng-container>

  <ng-template #spinner>
    <ng-container *ngIf="progressLabel ? progressLabel : label as label">
      <span class="cm-button-label">{{ label | translate }}</span>
    </ng-container>
    <div class="cm-button-loader"></div>
  </ng-template>
</button>
