<ng-container *ngTemplateOutlet="!link ? buttonTpl : linkTpl"> </ng-container>

<ng-template #linkTpl>
  <a
    [routerLink]="link"
    [routerLinkActive]="'active'"
    class="menu-item-icon tooltip tooltip--bottom"
    [attr.data-tooltip]="tooltip"
  >
    <span class="shape">
      <svg-icon src="/assets/icons/bg-shape.svg" class="bg"></svg-icon>
      <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
    </span>
    <span class="icon-label">
      {{ tooltip }}
    </span>
  </a>
</ng-template>

<ng-template #buttonTpl>
  <button type="button" class="menu-item-icon tooltip tooltip--bottom" [attr.data-tooltip]="tooltip">
    <span class="shape">
      <svg-icon src="/assets/icons/bg-shape.svg" class="bg"></svg-icon>
      <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
    </span>
    <span class="lg:hidden content">
      <ng-content select="[content]"></ng-content>
    </span>
  </button>
</ng-template>

<ng-template #contentTpl>
  <ng-content></ng-content>
</ng-template>
